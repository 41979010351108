<template>
  <div>
    <v-card>
      <v-card-title>Facturación por Wellboat</v-card-title>
      <v-card-text>
        <botonera 
          :botones="botones" 
          alinear="right"
          @crear="dialog = !dialog"
          @cargar="upload = !upload"
        ></botonera>
      </v-card-text>
    </v-card>
    <datatable 
      :cabeceras="facturacionListHeaders" 
      :items="facturacionListBody" 
      titulo="Listado de facturas" 
      label="Escriba para buscar factura" 
      icono="list" 
      color_icono="azul"
      :tachar="true"
      :acciones="['editar','eliminar']"
      @editar="editFactura($event)"
      @eliminar="deleteFactura($event)"
    >
    </datatable>
    <!-- Modal Nuevo Factura -->
    <v-dialog
      v-model="dialog"
      width="800px"
      @click:outside = "closeModal"
    >
      <v-card>
        <v-card-title class="blue darken-1">{{ title }}</v-card-title>
        <v-card-text>
          <v-row class="mx-2">
            <v-col cols="4">
              <v-select 
                prepend-icon="mdi-factory"
                v-model="facturacion.wellboat"
                :items="wellboats"
                item-text="nombre"
                item-value="id"
                label="Wellboats"
                color="blue darken-3"
                persistent-hint
                return-object
              >
              </v-select>
            </v-col>
            <v-col cols="4">
              <v-select 
                prepend-icon="mdi-factory"
                v-model="facturacion.empresa"
                :items="empresas"
                item-text="razon_social"
                item-value="id"
                label="Cliente"
                color="blue darken-3"
                persistent-hint
                return-object
              >
              </v-select>
            </v-col>
            <v-col cols="4">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fecha_formated"
                  label="Fecha"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
                </template>
                <v-date-picker
                  v-model = "facturacion.fecha"
                  @input = "menu1 = false"
                  locale = "es-cl"
                  prev-icon="chevron_left"
                  next-icon="chevron_right"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="facturacion.numero"
                label="Numero Factura"
                prepend-icon="mdi-calendar"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="facturacion.biomasa"
                label="Biomasa"
                prepend-icon="mdi-calendar"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="facturacion.pesos"
                label="Pesos"
                prepend-icon="mdi-calendar"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="facturacion.dolares"
                label="Dolares"
                prepend-icon="mdi-calendar"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
              <v-btn
                @click="closeModal"
              >Cerrar</v-btn>
              <v-btn
                color="primary"
                @click="saveFactura"
              >Grabar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- / Modal -->
    <!-- Modal upload -->
    <v-dialog
      v-model="upload"
      width="600px"
    >
      <v-card>
        <v-card-title class="green darken-1">
          Subir listado de facturas desde excel
        </v-card-title>
        <v-card-text>
          <v-row class="mx-2">
            <v-file-input v-model="file" accept=".xls, .xlsx" label="Archivo Excel"></v-file-input>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="upload = false"
          >Cancelar</v-btn>
          <v-btn
            color="primary"
            @click="uploadFacturas"
          >Subir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- / Modal -->
    <v-snackbar
      v-model="snackbar"
      :timeout="5000"
      :color="color"
    >
      {{ validation_message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import {mapState, mapMutations} from 'vuex'
  export default {
    data: () => ({
      title: 'Nueva Factura',
      color: 'green',
      snackbar: false,
      errors: [],
      validation_message: '',
      success: null,
      search: null,
      dialog: false,
      facturacionIndex: -1,
      wellboats: [],
      empresas: [],
      menu1: false,
      upload: false,
      file: null,
      facturacion: {
        id: 0,
        year: '',
        month: '',
        fecha: '',
        wellboat: {},
        empresa: {},
        numero: 0,
        biomasa: 0,
        pesos: 0,
        dolares:0
      },
      facturacionListHeaders: [
        {
          text: 'Año',
          align: 'center',
          sortable: true,
          value: 'year',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Mes',
          align: 'center',
          sortable: true,
          value: 'month',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Fecha',
          align: 'center',
          sortable: true,
          value: 'fecha',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Wellboat',
          align: 'center',
          sortable: true,
          value: 'wellboat.nombre',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Cliente',
          align: 'center',
          sortable: true,
          value: 'empresa.razon_social',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Numero',
          align: 'center',
          sortable: true,
          value: 'numero',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Biomasa',
          align: 'center',
          sortable: true,
          value: 'biomasa',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Pesos',
          align: 'center',
          sortable: true,
          value: 'pesos',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Dolares',
          align: 'center',
          sortable: true,
          value: 'dolares',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Acciones',
          align: 'center',
          sortable: false,
          value: 'actions',
          class: "blue-grey darken-4 white-text"
        },
      ],
      facturacionListBody: [],
      botones:[
        {boton:'crear',tooltip:'Nueva Factura'},
        {boton:'cargar',tooltip:'Carga Masiva'},
        //{boton:'eliminar',tooltip:'Borrar Todo'}
      ]
    }),
    mounted() {
      this.loadEmpresas();
      this.loadWellboats();
      this.loadFacturas();
    },
    methods:{
      async loadEmpresas(){
        let url = `${this.base_url}empresas/lista/select`;
        await this.axios.get(url, this.headers).then((response)=>{
          this.empresas = response.data;
        }).catch((error)=>{
            console.log(error);
        });
      },
      async loadWellboats(){
        let url = `${this.base_url}wellboats/lista/select`;
        await this.axios.get(url, this.headers).then((response)=>{
          this.wellboats = response.data;
        }).catch((error)=>{
            console.log(error);
        });
      },
      async loadFacturas(){
        this.mostrarLoading('Cargando listado de facturacion...')
        let url = `${this.base_url}facturacion_wellboat`;
        await this.axios.get(url, this.headers).then((response)=>{
          this.facturacionListBody = response.data;
          this.ocultarLoading()
        }).catch((error)=>{
            console.log(error);
            this.ocultarLoading()
        });
      },
      async saveFactura(){
        // validation
        this.errors = [];
        if(!this.facturacion.wellboat){
          this.errors.push("Debe indicar wellboat");
        }
        if(!this.facturacion.empresa){
          this.errors.push("Debe indicar cliente");
        }
        if(this.errors.length > 0){
          this.validation_message = this.errors.join();
          this.showSnackBar(true);
          return;
        }
        let url = `${this.base_url}facturacion_wellboat`;
        let facturacionData = {
          'wellboats_id': this.facturacion.wellboat.id,
          'empresas_id': this.facturacion.empresa.id,
          'fecha': this.facturacion.fecha,
          'numero': this.facturacion.numero,
          'biomasa': this.facturacion.biomasa,
          'pesos': this.facturacion.pesos,
          'dolares': this.facturacion.dolares,
        };
        if(this.facturacion.id > 0){
          url = url + "/" + this.facturacion.id;
          await this.axios.put(url, facturacionData, this.headers).then((response)=>{
            let index = this.facturacionListBody.map(facturacion => {
              return facturacion.id;
            }).indexOf(this.facturacion.id);
            this.facturacionListBody.splice(index, 1, response.data);
            this.showSnackBar(false);
          }).catch((error)=>{
              this.validation_message = error;
              this.showSnackBar(true);
          });
        }else{
          await this.axios.post(url, facturacionData, this.headers).then((response)=>{
              this.facturacionListBody.push(response.data);
              this.showSnackBar(false);
          }).catch((error)=>{
              this.errors = error.response.data;
              this.validation_message = this.errors.join();
              this.showSnackBar(true);
          });
        }
        this.cleanForm();
      },
      editFactura(item) {
        this.facturacionIndex = this.facturacionListBody.indexOf(item);
        this.facturacion = Object.assign({}, item);
        this.dialog = true;
        this.title = "Editar Factura";
      },
      async deleteFactura(item) {
        this.$confirm('Desea borrar este facturacion?').then(res => {
          if(res){
            let url = `${this.base_url}facturacion/${item.id}`;
            this.axios.delete(url, this.headers).then((response)=>{
              console.log(response);
              const index = this.facturacionListBody.indexOf(item);
              this.facturacionListBody.splice(index, 1);
              this.showSnackBar(false);
            }).catch((error)=>{
              this.validation_message = error;
              this.showSnackBar(true);
            });
          }
        });
      },
      async uploadFacturas(){
        this.headers.headers['Content-Type'] = "multipart/form-data";
        let url = `${this.base_url}uploads/facturas`;
        let formData = new FormData();
        formData.append("excel", this.file);
        let response = null; 
        try{
          response = await this.axios.post(url, formData, this.headers);
          this.file = null;
          this.upload = false;
          this.loadFacturas();
          this.showSnackBar(false);
        }catch(error){
          response = error.response;
          let msg = response.data.data;
          this.validation_message = response.status + ': ' + response.data.message + '. '+ msg;
          this.showSnackBar(true);
        }
      },
      showSnackBar(error){
        if(error){
          this.color = 'red';
        }else{
          this.color = 'green';
          this.validation_message = "Ejecutado exitosamente!";
        }
        this.snackbar = true;
      },
      closeModal(){
        this.cleanForm();
        this.dialog = false;
        this.error = null;
        this.title = "Nueva Factura";
      },
      cleanForm(){
        this.facturacion = {
          id: 0,
          nombre: '',
        };
        this.dialog = false;
      },
      async ocultar(item) {
        this.$confirm('Desea ocultar esta facturacion?').then(res => {
          if(res){
            let url = `${this.base_url}facturacion/ocultar/${item.id}`;
            this.axios.get(url, this.headers).then((response)=>{
              console.log(response);
              this.showSnackBar(false);
              this.loadFacturas();
            }).catch((error)=>{
              this.validation_message = error;
              this.showSnackBar(true);
            });
          }
        });
      },
      async mostrar(item) {
        this.$confirm('Desea mostrar esta facturacion?').then(res => {
          if(res){
            let url = `${this.base_url}facturacion/mostrar/${item.id}`;
            this.axios.get(url, this.headers).then((response)=>{
              console.log(response);
              this.showSnackBar(false);
              this.loadFacturas();
            }).catch((error)=>{
              this.validation_message = error;
              this.showSnackBar(true);
            });
          }
        });
      },
      formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${day}-${month}-${year}`
      },
      ...mapMutations(['actualizarBaseUrl', 'mostrarLoading', 'ocultarLoading']),
    },
    computed:{
      fecha_formated () {
        return this.formatDate(this.facturacion.fecha)
      },
      ...mapState(['loading', 'base_url', 'headers']),
    },
    components:{
      
    }
  }
</script>